<template>
  <div class="modal-container">
    <a-modal
      width="750px"
      :visible="visiable"
      title="新增记录"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form layout="vertical" style="width: 100%" :model="formData">
        <a-form-item label="" name="content">
          <a-textarea
            v-model:value="formData.content"
            placeholder="请输入记录内容"
            showCount
            :maxlength="200"
            :rows="4"
          />
        </a-form-item>
        <div class="chosenList">
          <div v-for="(item, index) in chosenContactList" :key="item.id">
            <div class="name">
              {{ item.name }}
              <CloseOutlined @click="deleteName(index)" />
            </div>
          </div>
        </div>
        <a-form-item label="" class="formItemClass">
          <a-input
            v-model:value="contactArr"
            placeholder="请添加联系人"
            @change="contactChange(contactArr)"
            @focus="contactFocus()"
            @blur="contactBlur()"
          >
          </a-input>
          <div v-if="showContactList" class="searchList">
            <div v-for="i in contactList" :key="i.id">
              <div class="selectList">
                <div class="selectName" @click="selectName(i)">
                  {{ i.name }}
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-row style="gap: 16px">
          <a-form-item label="" name="recordDate">
            <a-date-picker
              v-model:value="formData.recordDate"
              :getCalendarContainer="triggerNode => triggerNode.parentNode"
              value-format="yyyy-MM-DD HH:mm:ss"
              show-time
              placeholder="请选择时间"
              style="width: 218px"
              class="phoneCalssRight"
              :inputReadOnly="true"
            />
          </a-form-item>
          <a-form-item label="" name="recordType">
            <a-select
              style="width: 218px"
              v-model:value="formData.recordType"
              placeholder="请选择记录类型"
            >
              <template v-for="(item, index) in recordTypeList" :key="index">
                <a-select-option label="" :value="item.itemCode">
                  {{ item.itemNameCn }}
                </a-select-option>
              </template>
            </a-select>
          </a-form-item>
        </a-row>
        <a-form-item label="" class="formFileRow">
          <a-upload
            v-model:file-list="fileList"
            list-type="picture-card"
            @preview="filePreview"
            :customRequest="fileUpload"
            :beforeUpload="fileUploadBefore"
          >
            <div v-if="fileList.length < 8">
              <plus-outlined />
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :open="filePreviewVisible"
            :title="filePreviewTitle"
            :footer="null"
            @cancel="filePreviewCancel"
          >
            <img alt="example" style="width: 100%" :src="filePreviewImage" />
          </a-modal>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue';
import {
  Form as AForm,
  Row as ARow,
  DatePicker as ADatePicker,
  Select as ASelect,
  Upload as AUpload,
} from 'ant-design-vue';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons-vue';
import timelineApi from '@/api/timeline';

const AFormItem = AForm.Item;
const ASelectOption = ASelect.Option;

const props = defineProps({
  visiable: Boolean,
});

const emits = defineEmits(['closeModal']);

const formData = ref({
  content: '',
  contactIdList: [],
  recordDate: '',
  recordType: '',
});
const chosenContactList = ref([]);
const contactList = ref([]);
const showContactList = ref(false);
const contactArr = ref('');
const recordTypeList = ref([]);
const filePreviewImage = ref('');
const filePreviewVisible = ref(false);
const filePreviewTitle = ref('');
const fileUploadingSpace = ref(0);
const fileList = ref([
  {
    uid: '-1',
    name: 'image.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  },
]);

const handleCancel = () => {
  contactArr.value = '';
  emits('closeModal', false);
};

//联系人聊天框聚焦
const contactFocus = () => {
  timelineApi.queryContactByName('', {}).then(res => {
    if (res) {
      showContactList.value = true;
      getContactData(res);
    }
  });
};

//联系人聊天框失焦
const contactBlur = () => {
  showContactList.value = false;
};

//联系人索引改变
const contactChange = value => {
  timelineApi
    .queryContactByName('', {
      name: value,
    })
    .then(res => {
      if (res) {
        getContactData(res);
      }
    });
};

//获取联系人选择列表数据
const getContactData = res => {
  let notFoundItems = []; // 存储在list数组中存在但在a数组中不存在的项的新数组
  // 遍历 list 数组
  res.forEach(item => {
    // 使用 JSON.stringify 将对象转换为字符串，然后检查当前项是否在 a 数组中
    if (!chosenContactList.value.some(aItem => aItem.id === item.id)) {
      // 如果不在，将该项添加到 notFoundItems 数组中
      notFoundItems.push(item);
    }
  });
  // 打印在list数组中存在但在a数组中不存在的项
  if (notFoundItems.length > 0) {
    showContactList.value = true;
    contactList.value = notFoundItems;
  } else {
    showContactList.value = false;
  }
};

//选择联系人添加
const selectName = item => {
  if (chosenContactList.value.length > 0) {
    chosenContactList.value.forEach(i => {
      if (i.id !== item.id) {
        chosenContactList.value.push(item);
      }
    });
    // 数组对象去重的做法
    let unique = {};
    chosenContactList.value.forEach(item => {
      unique[item['id']] = item;
    });
    chosenContactList.value = Object.values(unique);
  } else {
    chosenContactList.value.push(item);
  }

  contactArr.value = '';
  contactList.value = [];
  showContactList.value = false;
};

//删除添加的联系人
const deleteName = index => {
  chosenContactList.value.splice(index, 1);
};

//获取记录类型列表
const getRecordType = () => {
  timelineApi.getRecordType('', {}).then(res => {
    if (res) {
      recordTypeList.value = res.filter(item => item.id != 2095);
    }
  });
};

//文件上传前检查
const fileUploadBefore = file => {
  let filesize = file.size;

  timelineApi.getUserFreeSpace('', {}).then(res => {
    if (res) {
      let allSpace = res.allSpace;
      let remainingSpace = res.remainingSpace;
      console.log('用户总存储空间:' + allSpace + ';已用空间:' + remainingSpace);

      if (remainingSpace + fileUploadingSpace.value + filesize > allSpace) {
        return false;
      }
    }
  });
};

//文件上传
const fileUpload = file => {
  let fileName = file.file.name;
  let formData = new FormData();
  let typeName = fileName.slice(fileName.lastIndexOf('.') + 1);
  // let typeName = file.file.type;
  formData.append('file', file.file, fileName);
  console.log(formData.get('file'));
  console.log(typeName);
  timelineApi
    .fileUpload('', {
      files: formData,
      bizType: typeName,
    })
    .then(res => {
      console.log(res);
      progress.percent = 99;
      fileUploadingSpace.value += file.file.size;
      file.onSuccess(res.data, file);
    });
  let progress = { percent: 1 };
  let speed = 100 / (file.file.size / 2000);
  let intervaler = setInterval(() => {
    if (progress.percent < 99 && progress.percent + speed < 100) {
      progress.percent += speed;
      file.onProgress(progress);
    } else if (progress.percent === 99) {
      progress.percent++;
    } else if (progress.percent === 100) {
      clearInterval(intervaler);
    }
  }, 100);
};

//文件转base64
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

//关闭文件预览
const filePreviewCancel = () => {
  filePreviewVisible.value = false;
  filePreviewTitle.value = '';
};

//打开文件预览
const filePreview = async file => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }
  filePreviewImage.value = file.url || file.preview;
  filePreviewVisible.value = true;
  filePreviewTitle.value =
    file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
};

onMounted(() => {
  getRecordType();
});
</script>

<style lang="less" scoped>
.formItemClass {
  position: relative;
}

.chosenList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-top: 16px;
  max-height: 68px;
  overflow: auto;

  .name {
    width: 88px;
    height: 24px;
    border-radius: 4px;
    //background: #FFFFFF;
    background: #fffaf6;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 8px 8px 0px;
  }
}

.searchList {
  max-height: 164px;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 24px;
  z-index: 2;
  margin-top: 16px;
  border: 1px solid #eeeff5;
  background: #fff;
  border-radius: 4px;

  .selectList {
    .selectName {
      height: 40px;
      //border-radius: 4px;
      background: #f5f6fa;
      font-size: 12px;
      line-height: 20px;
      //padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0px 8px 8px 0px;
      //padding: 10px 16px;
      cursor: pointer;
      padding-left: 16px;

      &:hover {
        background: #fffaf6;
      }
    }
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
